<template>
    <div class="sideContainer">
        <div class="sideContainer_left">
            <LocaleSwitcher/>
        </div>
        <div class="sideContainer_right">
            <div class="sideContainer_right_user">
                <b-avatar
                  variant="empty"
                  class="UserMenu__avatar"
                  :src="getAvatarIcon"
                />
                <div class="sideContainer_right_user_text">
                  <p class="text__psb">{{ user.name }}</p>
                  <span class="forgot_link">{{ user.email }}</span>
                </div>
            </div>
            <div class="sideContainer_right_links">
              <div class="sideContainer_right_links_list">
                <span
                  class="link"
                  v-on:click="updateTab('request')"
                >{{$i18n.t('lb_request_tab_table')}}</span>
                <span
                  class="link"
                  v-on:click="updateTab('sent')"
                >{{$i18n.t('lb_collaboration_tab_sent')}}</span>
                <span
                  class="link"
                  v-on:click="updateTab('received')"
                >{{$i18n.t('lb_collaboration_tab_received')}}</span>
              </div>
              <span class="link" v-on:click="closeSession">{{$i18n.t('btn_close_session')}}</span>
            </div>
        </div>
    </div>
</template>
<script>
import LocaleSwitcher from '@/components/organisms/LocaleSwitcher/LocaleSwitcher.vue';
import { getAvatarByProps, logout } from '@/helpers/UserHelper';

export default {
  created() {
    this.$store.dispatch('user/getUser');
  },
  methods: {
    closeSession() {
      this.$store.commit('initState');
      logout();
    },
    updateTab(tab) {
      this.currentTab = tab;
      this.closeMenu();
    },
    closeMenu() {
      const mobile = document.getElementById('mobile');
      mobile.classList.toggle('navigation');
    },
  },
  computed: {
    user() {
      return this.$store.getters['user/getCurrentUser'];
    },
    currentTab: {
      get() { return this.$store.state.ui.currentTab; },
      set(value) { this.$store.commit('ui/setCurrentTab', value); },
    },
    getAvatarIcon() {
      if (!this.user.avatar) return null;
      return getAvatarByProps(this.user.avatar);
    },
    getUserName() {
      if (!this.user.name) return null;
      return this.user.name;
    },
  },
  components: {
    LocaleSwitcher,
  },
};
</script>
<style lang="sass" scoped>
    @import './SideMenuMobile.sass'
</style>
